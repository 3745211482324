import { template as template_661bb512d8ff4b3eb07cbe08afd2add9 } from "@ember/template-compiler";
const FKText = template_661bb512d8ff4b3eb07cbe08afd2add9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
