import { template as template_700cd7c79f38459bbc5784047a50f0e7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_700cd7c79f38459bbc5784047a50f0e7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
