import { template as template_0d71b97682fe48308bdd5854e42c9981 } from "@ember/template-compiler";
const WelcomeHeader = template_0d71b97682fe48308bdd5854e42c9981(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
