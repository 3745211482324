import { template as template_a492aea349bc41ed867634cad796d26e } from "@ember/template-compiler";
const FKLabel = template_a492aea349bc41ed867634cad796d26e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
